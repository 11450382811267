import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Parameterize from 'parameterize';

const ArticleHelmet = ({ title, prefix }) => {
  const classPrefix = prefix ? `${prefix} ${prefix}-` : '';
  const fullClassName = classPrefix + Parameterize(title);

  const helmet_settings = {
    bodyAttributes: {
      class: `article ${fullClassName}`,
    },
    title: `${title}`,
    meta: [
      {
        property: 'og:title',
        content: `${title}`,
      },
    ],
  };

  return <Helmet {...helmet_settings} />;
};

ArticleHelmet.propTypes = {
  title: PropTypes.string.isRequired,
  prefix: PropTypes.string,
};

export default ArticleHelmet;
