import React from 'react';
import Link from 'gatsby-link';

import ViewAllIcon from '../../assets/images/icons/viewall.svg';
const ArticleFooter = () => {
  return (
    <section className="site-section blog-post__callout">
      <div className="row">
        <div className="eight columns offset-by-two">
          <Link className="button button-block" to="/blog"><img src={ViewAllIcon} alt="View All Icon" /> All Posts</Link>
        </div>
      </div>
    </section>
  );
};

export default ArticleFooter;
