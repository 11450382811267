import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import ArticleHelmet from 'components/Article/ArticleHelmet';
import ArticleBody from 'components/Article/ArticleBody';

// –––––––– Template ––––––––

export default function Template({ data }) {
  const { name, html } = data.jsonPage;

  return (
    <Layout>

      <article>
        <ArticleHelmet title={name} prefix="page" />

        <header className="article-header">
          <h1 itemProp="headline">{name}</h1>
        </header>

        <ArticleBody html={html} />
      </article>

    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.object.isRequired,
};

// –––––––– Query ––––––––

export const pageQuery = graphql`
  query($slug: String!) {
    jsonPage(slug: { eq: $slug }) {
      name
      slug
      html
    }
  }
`;
